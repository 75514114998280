<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">成绩查询</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="考生姓名" class="searchboxItem ci-full">
              <span class="itemLabel">考生姓名:</span>
              <el-input v-model="retrievalData.fullName" type="text" size="small" placeholder="考生姓名" clearable />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input v-model="retrievalData.idCard" type="text" size="small" placeholder="身份证号" clearable />
            </div>
            <div title="作业类别" class="searchboxItem ci-full">
              <span class="itemLabel">作业类别:</span>
              <el-select clearable size="small" v-model="retrievalData.workCategory" placeholder="请选择">
                <el-option v-for="item in workCategoryList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="考试结果" class="searchboxItem ci-full">
              <span class="itemLabel">考试结果:</span>
              <el-select clearable size="small" v-model="retrievalData.performanceState" placeholder="请选择">
                <el-option v-for="item in performanceStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" round @click="exportSeeExaminationRoom()">导出</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader3" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="考核日期" align="left" show-overflow-tooltip prop="examinationDate" width="120" />
              <el-table-column label="作业类别" align="left" show-overflow-tooltip prop="workCategory" width="100">
                <template slot-scope="scope">
                  {{ scope.row.workCategory ? $setDictionary("PA_WORK_CATEGORY", scope.row.workCategory) : "" }}
                </template>
              </el-table-column>
              <el-table-column label="考试计划名称" align="left" show-overflow-tooltip prop="planName" width="200" />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="fullName" width="100" />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idCard" width="160" />
              <el-table-column label="联系方式" align="left" show-overflow-tooltip prop="mobile" width="100" />
              <el-table-column label="科目" align="center" show-overflow-tooltip>
                <el-table-column label="科目一" align="left" show-overflow-tooltip prop="lastSubjectOneScore"
                  min-width="80" />
                <el-table-column label="科目二" align="left" show-overflow-tooltip prop="lastSubjectTwoScore"
                  min-width="80" />
                <el-table-column label="科目三" align="left" show-overflow-tooltip prop="lastSubjectThreeScore"
                  min-width="80" />
                <el-table-column label="科目四" align="left" show-overflow-tooltip prop="lastSubjectFourScore"
                  min-width="80" />
              </el-table-column>
              <el-table-column label="补考科目" align="center" show-overflow-tooltip>
                <el-table-column label="科目一" align="left" show-overflow-tooltip prop="subjectOneScore" min-width="80" />
                <el-table-column label="科目二" align="left" show-overflow-tooltip prop="subjectTwoScore" min-width="80" />
                <el-table-column label="科目三" align="left" show-overflow-tooltip prop="subjectThreeScore"
                  min-width="80" />
                <el-table-column label="科目四" align="left" show-overflow-tooltip prop="subjectFourScore"
                  min-width="80" />
              </el-table-column>>
              <el-table-column label="操作" align="center" width="140px" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="exportSeeExaminationRoomOne(scope.row)">下载</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "examinationAffairsManage_queryScore",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        fullName: "", // 姓名
        idCard: "", // 身份证号
        workCategory: "", // 作业类别
        performanceState: "", // 考试结果
      },
      workCategoryList: [], // 作业类别 - 下拉数据
      performanceStateList: [], // 考试结果 - 下拉数据
    };
  },
  created() {
    this.getDictionary();
  },
  watch: {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted() { },
  methods: {
    // 获取 - 字典
    getDictionary() {
      // 作业类别
      let workCategory = this.$setDictionary("PA_WORK_CATEGORY", "list");
      for (const key in workCategory) {
        this.workCategoryList.push({
          value: key,
          label: workCategory[key],
        });
      }
      // 考试结果
      let performanceState = this.$setDictionary("PA_PERFORMANCE_STATE", "list");
      for (const key in performanceState) {
        this.performanceStateList.push({
          value: key,
          label: performanceState[key],
        });
      }
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.retrievalData
      };
      this.doFetch({
        url: "/biz/plan/pa-plan-examination-performance/comp/pageList",
        params,
        pageNum,
      }, true, 10);
    },
    // 导出
    exportSeeExaminationRoom() {
      const params = {
        ... this.retrievalData
      };
      this.$post(
        "/biz/plan/pa-plan-examination-performance/comp/downloadScoreAll",
        params, 3000, true, 10
      )
        .then((res) => {
          const arr = res.data;
          for (let item of arr) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        })
        .catch(() => {
          return;
        });
    },
    // 单个导出
    exportSeeExaminationRoomOne(row) {
      this.$post("/biz/plan/pa-plan-examination-performance/comp/downloadScore", {
        examinationUserId: row.examinationUserId,
      }, 3000, true, 10).then((res) => {
        window.open(res.message);
      }).catch((err) => {
        console.log(err)
        return;
      });;
    },
  },
};
</script>
<style lang="less" scoped></style>