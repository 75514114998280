import { render, staticRenderFns } from "./queryScore.vue?vue&type=template&id=317c39a4&scoped=true"
import script from "./queryScore.vue?vue&type=script&lang=js"
export * from "./queryScore.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "317c39a4",
  null
  
)

export default component.exports